import React from "react";
import {Container,Row,Col,Button} from "react-bootstrap";
import CustomButton from "../../CustomButton";
import ButtonArrow from "../../../images/common/next3zeros_button_arrow_right.svg";
import * as style from "./index.module.scss";
import {PrismicRichText} from "@prismicio/react";

const HeroSection = ({data}) => {

    function handleClick() {
        window.location = '/contact-us'
    }

    return (
        <header className={style.hero_section}>
            <div className={style.hero_wrapper}>
                <Container className={style.hero_container}>
                    <div className={style.box}>
                        <img className={style.hero_img}
                                src={data.hero_side_image}
                                alt={data.hero_side_image_alt}
                        />
                        <div className={style.content_box}>
                            <div>
                                <PrismicRichText field={data.hero_title}/>
                            </div>
                            <p>
                                <PrismicRichText field={data.hero_description}/>
                            </p>
                            <CustomButton
                                label={data.hero_button_label}
                                prefixImage={null}
                                suffixImage={ButtonArrow}
                                className={style.hero_button}
                                onClick={handleClick}
                            />
                        </div>
                    </div>
                </Container>
            </div>
        </header>
    );
};

export default HeroSection;
