import * as React from "react";

import Layout from "../components/Layout";
import Seo from "../components/seo";
import {withPrismicPreview} from "gatsby-plugin-prismic-previews";
import HeroSection from "../components/Home/HeroSection";
import CardSection from "../components/Home/CardSection";
import WhyWeDoSection from "../components/Home/WhyWeDoSection";
import ProjectSection from "../components/Home/ProjectSection";
import OurPartnersSection from "../components/Home/OurPartnersSection";
import FrequentlyAskQuestionSection from "../components/Home/FrequentlyAskQuestionSection";
import CardGroupSection from "../components/Home/CardGroupSection";

import {graphql} from "gatsby"
import get from "lodash/get"

const HomePage = ({data, location}) => {

    const pageData = get(data, "prismicHomepage.dataRaw", {});
    const pageData_1 = get(data, "prismicOurPatnersSection.dataRaw", {});
    const pageCareers = get(data, "allPrismicCareersPage", {});

    const hero = {
        hero_title: get(pageData, "hero_title", []),
        hero_description: get(pageData, "hero_description", []),
        hero_side_image: get(pageData, "hero_side_image.url", ""),
        hero_side_image_alt: get(pageData, "hero_side_image.alt", ""),
        hero_button_label: get(pageData, "hero_button_label", ""),
        hero_button_link: get(pageData, "hero_button_link", ""),
    }

    const partners = {
        carousal_title: get(pageData_1, "carousal_title", []),
        carousal_description: get(pageData_1, "carousal_description", []),
        our_partners_carousal: get(pageData_1, "our_partners_carousal", ""),
    }

    const faq = {
        faq_top: get(pageData, "faq_top", []),
        faq_title: get(pageData, "faq_title", []),
        faq_description: get(pageData, "faq_description", ""),
        faq_card: get(pageData, "faq_card", ""),
        person_a: get(pageData, "person_a.url", ""),
        person_b: get(pageData, "person_b.url", ""),
        person_c: get(pageData, "person_c.url", ""),
        bottom_description: get(pageData, "bottom_description", ""),
        buttom_title: get(pageData, "buttom_title", ""),
        button_text: get(pageData, "button_text", ""),
    }

    const project_card = {
        project_card: get(pageData, "project_card", [])
    }

    const why_we_do = {
        why_we_do_description: get(pageData, "why_we_do_description", []),
        why_we_do_title: get(pageData, "why_we_do_title", [])
    }

    const card = {
        card_1_button: get(pageData, "card_1_button", []),
        card_1_hover_button_text: get(pageData, "card_1_hover_button_text", []),
        card_1_hover_description: get(pageData, "card_1_hover_description", []),
        card_1_hover_list: get(pageData, "card_1_hover_list", []),
        card_1_hover_title: get(pageData, "card_1_hover_title", []),
        card_1_sub_title: get(pageData, "card_1_sub_title", []),
        card_1_title: get(pageData, "card_1_title", []),
        card_2_button: get(pageData, "card_2_button", []),
        card_2_hover_button_text: get(pageData, "card_2_hover_button_text", []),
        card_2_hover_description: get(pageData, "card_2_hover_description", []),
        card_2_hover_list: get(pageData, "card_2_hover_list", []),
        card_2_hover_title: get(pageData, "card_2_hover_title", []),
        card_2_sub_title: get(pageData, "card_2_sub_title", []),
        card_2_title: get(pageData, "card_2_title", []),
        card_3_button: get(pageData, "card_3_button", []),
        card_3_hover_button_text: get(pageData, "card_3_hover_button_text", []),
        card_3_hover_description: get(pageData, "card_3_hover_description", []),
        card_3_hover_list: get(pageData, "card_3_hover_lisit", []),
        card_3_hover_title: get(pageData, "card_3_hover_title", []),
        card_3_sub_title: get(pageData, "card_3_sub_title", []),
        card_3_title: get(pageData, "card_3_title", []),
    }

    const card_group = {
        tab_data: get(pageData, "tab", []),
    }

    return (
        <Layout location={location} title={''}>
            <HeroSection data={hero}/>
            <CardSection data={card}/>
            <WhyWeDoSection data={why_we_do}/>
            {/*<ProjectSection data={project_card}/>*/}
            <OurPartnersSection data={partners}/>
            {/*<CardGroupSection data={pageCareers}/>*/}
            <FrequentlyAskQuestionSection data={faq}/>
        </Layout>
    );
};

export const Head = ({data}) => {
    return <Seo title={'Home Page'} description={'excerpt'}/>;
};

export default withPrismicPreview(HomePage);

export const query = graphql`
  query seoPersonalInsuranceQuery{
    prismicHomepage{
      dataRaw
    }
    prismicOurPatnersSection{
      dataRaw
    }
  }
`
