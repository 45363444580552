import React, {useState, useEffect} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Accordion from 'react-bootstrap/Accordion';
import ButtonArrow from "../../../images/common/next3zeros_button_arrow_right.svg";
import ButtonCall from "../../../images/common/next3zeros_button_call.svg";
import * as faq from "./index.module.scss";
import {PrismicRichText} from "@prismicio/react";
import CustomButton from "../../CustomButton";

const FrequentlyAskQuestionSection = ({data}) => {

    const according_data = data.faq_card;


    function handleClick(){
        window.location = '/contact-us'
    }


    return (
        <section className={`section_frequently_ask_question ${faq.frequently_question_section}`}>
            <Container>
                <Row>
                    <Col xxxl={2} xxl={2} xl={2} lg={2} md={12} sm={12} xs={12} xxs={12}></Col>
                    <Col xxxl={8} xxl={8} xl={8} lg={8} md={12} sm={12} xs={12} xxs={12}>
                        <div className={faq.frequently_question_section_area}>
                            {/*Title*/}
                            <div className={faq.frequently_question_section_area_title}>
                                <PrismicRichText field={data.faq_title}/>
                            </div>
                            {/*Description*/}
                            <div className={faq.frequently_question_section_area_sub}>
                                <PrismicRichText field={data.faq_description}/>
                            </div>
                        </div>
                    </Col>
                    <Col xxxl={2} xxl={2} xl={2} lg={2} md={12} sm={12} xs={12} xxs={12}></Col>
                </Row>
                <Row>
                    <Col xxxl={2} xxl={2} xl={2} lg={2} md={12} sm={12} xs={12} xxs={12}></Col>
                    <Col xxxl={8} xxl={8} xl={8} lg={8} md={12} sm={12} xs={12} xxs={12}>
                        <Accordion defaultActiveKey="0" flush>

                            {
                                according_data.map((item, idx) => {
                                    return (
                                        <Accordion.Item eventKey={idx}>
                                            {/*Accordion Title*/}
                                            <Accordion.Header>
                                                {item.according_title}
                                            </Accordion.Header>

                                            {/*Accordion Description*/}
                                            <Accordion.Body>
                                                <PrismicRichText field={item?.faq_description_view||[]}/>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )
                                })
                            }
                        </Accordion>
                    </Col>
                    <Col xxxl={2} xxl={2} xl={2} lg={2} md={2} sm={12} xs={12} xxs={12}></Col>
                </Row>
                <Row>
                    <Col xxxl={2} xxl={2} xl={2} lg={2} md={12} sm={12} xs={12} xxs={12}></Col>
                    <Col xxxl={8} xxl={8} xl={8} lg={8} md={12} sm={12} xs={12} xxs={12}>
                        <div className={faq.frequently_question_still_question_section}>
                            <ul className={faq.frequently_question_still_question_section_img_list}>
                                <li>
                                    <a href="#">
                                        <img className={faq.frequently_question_still_question_section_img}
                                             src={data.person_a}/>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <img className={faq.frequently_question_still_question_section_img}
                                             src={data.person_b}/>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <img className={faq.frequently_question_still_question_section_img}
                                             src={data.person_c}/>
                                    </a>
                                </li>
                            </ul>

                            {/*Bottom Title*/}
                            <div className={faq.frequently_question_still_question_section_title}>
                                <PrismicRichText field={data.buttom_title}/>
                            </div>

                            {/*Bottom Description*/}
                            <div className={faq.frequently_question_still_question_section_para}>
                                <PrismicRichText field={data.bottom_description}/>
                            </div>

                            {/*Button Text*/}
                            <Button className={faq.frequently_question_still_question_section_button}
                                    type="button"
                                    variant="primary"
                                    onClick={handleClick}>
                                <img className={faq.frequently_question_still_question_section_button_call}
                                     src={ButtonCall}
                                />
                                {data.button_text}
                                <img className={faq.frequently_question_still_question_section_button_arrow}
                                     src={ButtonArrow}
                                />
                            </Button>
                        </div>
                    </Col>
                    <Col xxxl={2} xxl={2} xl={2} lg={2} md={12} sm={12} xs={12} xxs={12}></Col>
                </Row>
            </Container>
        </section>
    );
};

export default FrequentlyAskQuestionSection;
