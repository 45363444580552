// extracted by mini-css-extract-plugin
export var frequently_question_section = "index-module--frequently_question_section--e9aab";
export var frequently_question_section_area = "index-module--frequently_question_section_area--3a2d2";
export var frequently_question_section_area_faqs = "index-module--frequently_question_section_area_faqs--6e0a6";
export var frequently_question_section_area_sub = "index-module--frequently_question_section_area_sub--d3d5c";
export var frequently_question_section_area_title = "index-module--frequently_question_section_area_title--22b2c";
export var frequently_question_still_question_section = "index-module--frequently_question_still_question_section--4c881";
export var frequently_question_still_question_section_button = "index-module--frequently_question_still_question_section_button--89ca8";
export var frequently_question_still_question_section_button_arrow = "index-module--frequently_question_still_question_section_button_arrow--82e5a";
export var frequently_question_still_question_section_button_call = "index-module--frequently_question_still_question_section_button_call--5ad3a";
export var frequently_question_still_question_section_img = "index-module--frequently_question_still_question_section_img--b0ddf";
export var frequently_question_still_question_section_img_list = "index-module--frequently_question_still_question_section_img_list--0db58";
export var frequently_question_still_question_section_para = "index-module--frequently_question_still_question_section_para--611e1";
export var frequently_question_still_question_section_title = "index-module--frequently_question_still_question_section_title--4aa71";